<template>
  <pageBack>
    <div class="title">申请个人FunPinPin账号</div>
    <div class="small">
      注册成功即可享受免费试用！
    </div>
    <div class="stepStrip " :class="'active' + stepNumber">
      <div class="stepType">
        <div class="icon"></div>
        <div class="sign">注册账号</div>
      </div>
      <div class="stepLine"></div>
      <div class="stepType">
        <div class="icon"></div>
        <div class="sign">完善信息</div>
      </div>
      <div class="stepLine"></div>
      <div class="stepType">
        <div class="icon"></div>
        <div class="sign">提交审核</div>
      </div>
    </div>
    <div class="frombody">
      <router-view />
    </div>
  </pageBack>
</template>

<script>
import pageBack from "@/components/basic/pageBack.vue";
export default {
  name: "enterprise",
  components: { pageBack },
  data() {
    return {
      stepNumber: 1
    };
  },
  watch: {
    $route: {
      handler(vel) {
        if (vel.path.match(/Step2/)) this.stepNumber = 2;
        if (vel.path.match(/Step3/)) this.stepNumber = 3;
      },
      deep: true,
      immediate: true
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/common.scss";
.title {
  height: 45px;
  line-height: 45px;
  text-align: center;
  margin-top: 70px;
  font-size: 32px;
  font-weight: 600;
  color: $black;
}
.small {
  height: 28px;
  margin-top: 15px;
  color: $orange-font;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}
.stepStrip {
  width: 758px;
  margin: 52px auto 0;
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.active2 {
    :nth-child(1) {
      .icon {
        background-image: url("../../../assets/images/register/active.png") !important;
      }
    }
    :nth-child(3) {
      .icon {
        background-image: url("../../../assets/images/register/active2.png");
      }
    }
  }
  &.active3 {
    :nth-child(1) {
      .icon {
        background-image: url("../../../assets/images/register/active.png");
      }
    }
    :nth-child(3) {
      .icon {
        background-image: url("../../../assets/images/register/active.png");
      }
    }
    :nth-child(5) {
      .icon {
        background-image: url("../../../assets/images/register/active3.png");
      }
    }
  }
  .stepType {
    width: 136px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .icon {
      width: 39px;
      height: 39px;
      background-image: url("../../../assets/images/register/active1.png");
      background-size: 39px 39px;
      background-position: center center;
      background-repeat: no-repeat;
    }
    .sign {
      margin-left: 10px;
      font-size: 20px;
      font-weight: 400;
      color: $black;
    }
  }
  :nth-child(3) {
    .icon {
      background-image: url("../../../assets/images/register/num2.png");
    }
  }
  :nth-child(5) {
    .icon {
      background-image: url("../../../assets/images/register/num3.png");
    }
  }
  .stepLine {
    width: 135px;
    // margin-left: 15px;
    // margin-right: 25px;
    height: 1px;
    background-color: $greyLiner;
  }
}
.frombody {
  width: 570px;
  margin: 56px auto 0;
}
</style>
136(type) 17 =153 15 135(xian) 25 =175 350
